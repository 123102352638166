/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.logo-wide {
  height: 80%;
}

.ant-table {
  line-height: 1;
}

.ant-table-thead > tr > th {
  overflow-wrap: unset;
  height: 40px;
}

.ant-table-body {
  min-height: fit-content;
}

.ant-table-tbody > tr > td {
  overflow-wrap: break-word;
  min-height: fit-content;
}

.anticon {
  vertical-align: -1px;
}

.ant-form-item-label {
  white-space: normal;
}

.logo {
  text-align: center;
  height: 115px;
  margin: 0px 5px 0px 5px;
}

.logo-collapsed {
  width: 25px;
  margin-top: 15px;
}

.logo-opened {
  margin-top: 10px;
  height: 90px;
}

.ant-layout-sider {
  flex: 0 0 240px !important;
  max-width: 240px !important;
  min-width: 240px !important;
  width: 240px !important;
}

.ant-layout-sider-trigger {
  width: 240px !important;
}

.ant-layout-sider-collapsed .ant-layout-sider-trigger {
  width: 35px !important;
}

.ant-layout-sider-collapsed {
  flex: 0 0 35px !important;
  max-width: 35px !important;
  min-width: 35px !important;
  width: 35px !important;
}

.ant-layout-content {
  padding: 0px 10px;
}

.ant-page-header {
  padding: 10px 0px;
}

.cursorPointer {
  cursor: pointer;
}

.form {
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.travel-cost-form {
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  height: auto;
}

.header {
  height: 35px;
}

.ant-menu.header-menu {
  line-height: 35px;
}

.ant-menu-root {
  max-height: calc(100vh - 329px);
  overflow-y: auto;
}

.checkIconFilter {
  width: 100%;
  color: green;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeIconFilter {
  width: 100%;
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-input-number-disabled {
  background-color: transparent !important;
  color: black;
}

.ant-select {
  width: 100%;
}

.smaller-gap-row .ant-form-item {
  margin-bottom: 4px;
}

.ant-input-number-group-wrapper {
  width: 100%;
}

.ant-input-number {
  width: 100%;
}

.ant-picker {
  width: 100%;
}

.ant-breadcrumb {
  text-align: left;
}

.dummy-label label {
  display: none;
}

.clickable-table-div {
  width: 100%;
  text-align: center;
}

.page-header-wrapper .ant-select {
  width: 200px;
}

.page-header-wrapper .ant-picker {
  width: 200px;
}

.clickable-table-button {
  border-color: #d9d9d9 !important;
  border-style: dashed !important;
  background-color: white !important;
  white-space: normal;
  height: 100%;
}

.clickable-table-button:hover {
  border-color: #1890ff !important;
  color: #1890ff !important;
}

.clickable-table-button:focus {
  border-color: #1890ff !important;
  color: #1890ff !important;
}

.delete-button-enabled {
  min-width: 100%;
  background-color: red;
  border: 1px solid red;
  color: white;
}

.delete-button-disabled {
  min-width: 100%;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.language-wrapper {
  padding: 0px 50px;
  width: 100%;
  height: 40px;
}

.language-wrapper img {
  max-width: 40px;
}

.language-wrapper img:first-child {
  float: left;
}

.language-wrapper img:last-child {
  float: right;
}

.language-wrapper-none {
  display: none;
}

.ant-btn .anticon {
  vertical-align: 2px;
}

.hide-main-layout {
  display: none;
}

.full-screen-sider .ant-layout-sider {
  flex: 0 0 100vw !important;
  max-width: 100vw !important;
  min-width: 100vw !important;
  width: 100vw !important;
}

.full-screen-sider .ant-layout-sider-trigger {
  width: 100vw !important;
}

.full-screen-sider .ant-layout-sider-collapsed .ant-layout-sider-trigger {
  width: 35px !important;
}

.full-screen-sider .ant-layout-sider-collapsed {
  flex: 0 0 35px !important;
  max-width: 35px !important;
  min-width: 35px !important;
  width: 35px !important;
}

.identity-navmenu a {
  color: rgba(255, 255, 255, 0.65) !important;
  padding: 5px 25px;
  display: flex;
}

.login-nav-menu-text {
  margin-left: 10px;
  margin-top: -4px;
}

.identity-navmenu ::marker {
  display: none;
}

.date-picker-filter .ant-picker {
  width: 120px;
}

.date-picker-filter .ant-btn {
  width: 120px;
}

.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
  padding: 1px 3px;
}
