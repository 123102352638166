.calculation-column {
  width: 120px;
  border-left: 1px solid #f0f0f0;
  padding: 4px 4px;
  font-weight: 600;
  position: relative;
  right: 4px;
}

.payoffTable .ant-table-footer {
  padding: 0px 0px !important;
}

.calculation-column-total {
  font-weight: 600;
  padding: 4px 4px;
}

.payoffTable .ant-table.ant-table-small .ant-table-title,
.payoffTable .ant-table.ant-table-small .ant-table-footer,
.payoffTable .ant-table.ant-table-small .ant-table-thead > tr > th,
.payoffTable .ant-table.ant-table-small .ant-table-tbody > tr > td,
.payoffTable .ant-table.ant-table-small tfoot > tr > th,
.payoffTable .ant-table.ant-table-small tfoot > tr > td {
  padding: 4px 4px;
}
