.loading {
    z-index: 10000;
    text-align: center;
    position: fixed;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.loading .ant-spin.ant-spin-spinning {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

.ant-spin-nested-loading>div>.ant-spin .ant-spin-text {
    max-width: 470px;
}

.ant-spin.ant-spin-show-text .ant-spin-text {
    font-size: 30px;
    font-weight: 500;
    color: white;
}

.ant-spin-dot {
    margin-bottom: 14px;
}