.home-screen {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-screen-image {
  height: 350px;
  margin-bottom: -10px;
  margin-top: -200px;
}

.home-screen-image-mobile {
  height: 180px;
  margin-bottom: -70px;
  margin-top: -250px;
}

.home-screen-title {
  color: #003749;
  font-weight: 500;
  font-size: 30px;
  letter-spacing: 8px;
  text-align: center;
}

.home-screen-title-mobile {
  color: #003749;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 8px;
  text-align: center;
}

.home-screen-text {
  color: #003749;
  font-weight: 400;
  font-size: 25px;
  text-align: center;
}

.home-screen-text-mobile {
  color: #003749;
  font-weight: 400;
  font-size: 17px;
  text-align: center;
  align-items: center;
}

.company-info {
  font-size: 10px;
  position: relative;
  bottom: -220px;
}

.company-info-mobile {
  font-size: 10px;
  position: relative;
  bottom: -220px;
}
