.preview-image-wrapper {
  margin-top: 25px;
}

.preview-image {
  max-height: 180px;
  max-width: 100%;
}

.image-delete-icon {
  height: 100%;
  translate: -60%;
}

.image-row {
  margin-bottom: 10px;
}
