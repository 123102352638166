.smaller-gap-row .ant-descriptions-row > th, .ant-descriptions-row > td {
    padding-bottom: 5px;
}

.mobile-report-select .ant-space-item {
    width: 100px;
}

.mobile-report-select .ant-space-item:last-child {
    width: 100px;
    margin-left: 20px;
}